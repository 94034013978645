import { usePlayerControls } from "@/Hooks/usePlayerControls";
import Slider from "@react-native-community/slider";
import { useNavigation } from "@react-navigation/native";
import React from "react";
import {
    Pressable,
    View,
    Text,
    StyleSheet,
    TouchableOpacity
} from "react-native";
import { Image } from "expo-image";

import { Ionicons } from "@expo/vector-icons";

export const FloatingPlayer = () => {
    const { navigate } = useNavigation();
    const {
        currentTrack,
        currentTrackIndex,
        controls: {
            isPlaying,
            startTrack,
            positionMillis,
            durationMillis,
            seekTo
        }
    } = usePlayerControls();

    if (!currentTrack) {
        return null;
    }

    const { artist, title, artwork } = currentTrack;

    const playerPressHandler = () => {
        navigate("MusicPlayer", { index: currentTrackIndex });
    };

    return (
        <Pressable style={styles.container} onPress={playerPressHandler}>
            {artwork && (
                <Image source={{ uri: artwork }} style={styles.artworkImage} />
            )}
            <View style={styles.trackInfo}>
                <Text style={styles.title}>{title}</Text>
                <Text style={styles.artist}>{artist}</Text>
            </View>
            <Slider
                style={styles.slider}
                maximumValue={durationMillis}
                minimumValue={0}
                value={positionMillis}
                thumbTintColor="#005ce6"
                minimumTrackTintColor="#005ce6"
                maximumTrackTintColor="#FFFFFF"
                onSlidingComplete={(value) => {
                    // Handle track seeking
                    // ...
                    seekTo(value);

                    console.log("value", value);
                }}
            />
            <TouchableOpacity onPress={startTrack}>
                <Ionicons
                    name={isPlaying ? "pause" : "play"}
                    size={24}
                    color="#005ce6"
                    style={styles.playButton}
                />
            </TouchableOpacity>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: "#000",
        flexDirection: "row",
        alignItems: "center",
        borderTopWidth: 1,
        borderTopColor: "#005ce6",
        height: 80
    },
    artworkImage: {
        width: 36,
        height: 36,
        borderRadius: 18,
        marginRight: 8
    },
    trackInfo: {
        flex: 1,
        marginLeft: 8
    },
    title: {
        fontSize: 14,
        fontWeight: "bold",
        color: "#FFFFFF",
        marginBottom: 4
    },
    artist: {
        fontSize: 12,
        color: "#FFFFFF"
    },
    slider: {
        flex: 1,
        marginLeft: 8
    },
    playButton: {
        marginLeft: 8
    }
});

export default FloatingPlayer;
