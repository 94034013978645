import { Conversation, Media, Message, Paginator } from "@twilio/conversations";
import { IMessage } from "react-native-gifted-chat";
import moment from "moment";

export * from "./User";
export * from "./Analytics";

export interface TDIMessage extends IMessage {
    documentUri?: string;
}

export interface TDConversation {
    chatName: string;
    chatInitials: string;
    participants: TDParticipant[];
    lastMessage?: string | null | undefined;
    messages: TDConversationMessage[] | undefined;
    unreadCount?: number | null;
    dateCreated: string | null;
    dateUpdated: string | number;
    projectId: string;
    type: "oneOnOne" | "group" | "bulletin";
    externalId: string;
    twilioMessagePaginator?: TDPaginator;
}
export interface TDPaginator {
    hasPrevPage: boolean;
    prevPage: Promise<Paginator<Message>>;
}

export interface TDConversationMedia {
    mediaItem: Media;
    fileName: string | null;
    contentType: string;
}

export interface TDConversationMessage {
    message: string;
    dateCreated: string;
    authorUserId: string;
    sid: string;
    isSelf: boolean;
    pinnedMessageId: string | undefined;
    messageType: string;
    isMedia: boolean;
    attachedMediaList: TDConversationMedia[] | [];
}

export interface TDParticipant {
    firstName: string;
    lastName: string;
    userId: string;
    companyName: string;
    companyId: string;
    type: "chat" | "sms" | "whatsapp" | "email";
    phoneNumber?: string;
    participantSid: string;
}

export enum ProjectConversationType {
    oneOnOne = "oneOnOne",
    group = "group",
    bulletin = "bulletin"
}

const mapMedia = (m: Message) => {
    if (!(m.type === "media" && m.attachedMedia)) {
        return [];
    }
    if (m.attachedMedia && m.attachedMedia.length <= 0) {
        return [];
    }

    const messageMedia: TDConversationMedia[] = [];

    for (const mediaItem of m.attachedMedia) {
        messageMedia.push({
            mediaItem,
            fileName: mediaItem.filename,
            contentType: mediaItem.contentType
        });
    }

    console.log("messageMedia mediaUrl", messageMedia);
    return messageMedia;
};

export const mapTDMessage = (message: Message): TDConversationMessage => {
    const messagePrefix = message.attributes["messagePrefix"];
    const messageSuffix = message.attributes["messageSuffix"];
    const body = message.body
        ?.replace(messagePrefix, "")
        ?.replace(messageSuffix, "");

    return {
        message: body,
        dateCreated: moment(message.dateCreated).format("lll"),
        authorUserId: message.author,
        sid: message.sid,
        isSelf: false,
        pinnedMessageId: "",
        messageType: message.type,
        isMedia: message.type === "media",
        attachedMediaList: mapMedia(message)
    };
};

export const mapConversation = (
    twilioConversation: Conversation,
    currentUserId: string
) => {
    let otherParticipant!: any;
    let chatName: string = "";
    let chatInitials: string = "";
    const conversationAttributes = twilioConversation.attributes;
    const tdParticipants = conversationAttributes[
        "tdParticipants"
    ] as TDParticipant[];
    const isGroupChat =
        conversationAttributes["type"] === ProjectConversationType.group;

    if (isGroupChat) {
        chatName =
            twilioConversation.friendlyName ??
            tdParticipants.map((x) => x.firstName).join(", ");
    } else {
        otherParticipant = tdParticipants.find(
            (p) => p.userId !== currentUserId
        )!;
        chatName = `${otherParticipant?.firstName} ${otherParticipant?.lastName}`;
        chatInitials = `${otherParticipant?.firstName?.charAt(
            0
        )!}${otherParticipant?.lastName?.charAt(0)!}`;
    }

    const conversation: TDConversation = {
        externalId: twilioConversation.sid,
        chatName,
        chatInitials,
        projectId: conversationAttributes["projectId"],
        dateCreated: twilioConversation.dateCreated.toDateString(),
        dateUpdated: twilioConversation.dateUpdated.getTime(),
        type: conversationAttributes["type"],
        participants: tdParticipants,
        messages: [],
        unreadCount: 0,
        twilioMessagePaginator: null
    };
    return conversation;
};

export const mapIMessages = async (tdMessages: TDConversationMessage[]) => {
    const messages = [];
    for (const tdMessage of tdMessages) {
        console.log("tdMessage debug", tdMessage);
        if (tdMessage.isMedia) {
            for (const media of tdMessage.attachedMediaList) {
                if (media.contentType.includes("image/")) {
                    messages.push({
                        _id: tdMessage.sid,
                        createdAt: moment(
                            tdMessage.dateCreated,
                            "lll"
                        ).toDate(),
                        user: {
                            _id: tdMessage.authorUserId,
                            name: tdMessage.authorUserId,
                            avatar: "https://facebook.github.io/react/img/logo_og.png"
                        },
                        image: await media.mediaItem.getContentTemporaryUrl()
                    });
                } else {
                    messages.push({
                        _id: tdMessage.sid,
                        createdAt: moment(
                            tdMessage.dateCreated,
                            "lll"
                        ).toDate(),
                        text: media.fileName,
                        user: {
                            _id: tdMessage.authorUserId,
                            name: tdMessage.authorUserId,
                            avatar: "https://facebook.github.io/react/img/logo_og.png"
                        },
                        documentUri:
                            await media.mediaItem.getContentTemporaryUrl()
                    });
                }
            }
        } else {
            messages.push({
                _id: tdMessage.sid,
                text: tdMessage.message,
                createdAt: moment(tdMessage.dateCreated, "lll").toDate(),
                user: {
                    _id: tdMessage.authorUserId,
                    name: tdMessage.authorUserId,
                    avatar: "https://facebook.github.io/react/img/logo_og.png"
                }
            });
        }
    }
    return messages;
};
