/**
 * Used to navigating without the navigation prop
 * @see https://reactnavigation.org/docs/navigating-without-navigation-prop/
 *
 * You can add other navigation functions that you need and export them
 */
import { Track } from "@/Models/Track";
import {
    CommonActions,
    createNavigationContainerRef
} from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

export enum Routes {
    MusicPlayer = "MusicPlayer",
    HOME = "Home"
}

type RootStackParamList = {
    Startup: undefined;
    Home: undefined;
    PasswordReset: undefined;
    RequestLoginAccess: undefined;
    ConfirmPasswordReset: undefined;
    ChatDetail: undefined;
    CreateConversationModal: undefined;
    [Routes.MusicPlayer]: { index: number; position?: number; queue: Track[] };
};

export type RootStackScreenProps<T extends Routes> = NativeStackScreenProps<
    RootStackParamList,
    T
>;

export const navigationRef = createNavigationContainerRef<RootStackParamList>();

export function navigate(name: keyof RootStackParamList, params?: any) {
    if (navigationRef.isReady()) {
        navigationRef.navigate(name, params);
    }
}

export function goNavigateBack() {
    if (navigationRef.isReady()) {
        navigationRef.goBack();
    }
}

export function navigateAndReset(routes = [], index = 0) {
    if (navigationRef.isReady()) {
        navigationRef.dispatch(
            CommonActions.reset({
                index,
                routes
            })
        );
    }
}

export function navigateAndSimpleReset(name: string, index = 0) {
    if (navigationRef.isReady()) {
        navigationRef.dispatch(
            CommonActions.reset({
                index,
                routes: [{ name }]
            })
        );
    }
}
