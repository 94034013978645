import { useNavigation } from "@react-navigation/native";
import axios from "axios";
import * as Linking from "expo-linking";
import { useEffect } from "react";

import { firebaseMain } from "@/firebaseConfig";

function extractActionCodeFromURL(url: string | URL) {
  console.log("url", url);
  const urlObject = new URL(url);
  const actionCode = urlObject.searchParams.get("code");
  const email = urlObject.searchParams.get("email");

  return { actionCode, email };
}

function extractMixcloudCodeFromURL(url: string | URL) {
  const urlObject = new URL(url);
  const actionCode = urlObject.searchParams.get("code");

  return { actionCode };
}

const clientId = "coIZ2SMedvqPCEDLYV";

export const AuthListener = () => {

  //const history = useHistory();
  const navigation = useNavigation();

  const handleMagicLink = (url: string) => {
    if (!url.startsWith("serauto://auth/complete")) {
      return;
    }
    // Process the URL and complete the sign-in
    const { actionCode, email } = extractActionCodeFromURL(url);
    console.log("authing with", email, actionCode);

    firebaseMain
      .auth()
      .signInWithCustomToken(actionCode ?? "")
      .then(() => {
        // Successfully signed in
        console.log("signed in");
        navigation.navigate("Main");
      })
      .catch((error) => {
        // Handle errors
        console.log("signin error", error);
      });
  };
  useEffect(() => {
    const handleOpenURL: Linking.URLListener = (url) => {
      console.log("handleOpenURL", url);
      handleMagicLink(url.url);
      handleMixCloudLogin(url.url);
    };
    Linking.addEventListener("url", handleOpenURL);

    // Clean up on unmount
    return () => {};
  }, []);
  const handleMixCloudLogin = async (url: string) => {
    if (!url.startsWith("serauto://auth/mixcloud")) {
      return;
    }
    // Process the URL and complete the sign-in
    const { actionCode } = extractMixcloudCodeFromURL(url);
    if (!actionCode) {
      return;
    }
    console.log("authing with", actionCode);
    const tokenUrl = "https://www.mixcloud.com/oauth/access_token";
    const redirectUri = "serauto://auth/mixcloud";

    const tokenResponse = await fetch(tokenUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        client_id: clientId,
        client_secret: "gWMnpdhqrWCV4o112t05te86VcwkWtkG",
        redirect_uri: redirectUri,
        code: actionCode,
        grant_type: "authorization_code",
      }),
    });
    const { access_token: accessToken } = await tokenResponse.json();
    console.log("accessToken", accessToken);
    /*firebaseMain
            .firestore()
            .collection("integrations")
            .doc(firebaseMain.auth().currentUser?.uid)
            .update({
                mixcloud: {
                    accessToken
                }
            });*/
  };

  // ... Other logic ...

  return null;
};
