import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "Services/modules/users/fetchUserInfo";
export interface ChatState {
    projectId?: string;
    projectUserList: ProjectUser[];
}

export interface ProjectUser {
    CreatedAt: string | number;
    CreatedBy: string | null;
    ModifiedAt: string | number;
    ModifiedBy: string | null;
    ProjectId: string;
    ProjectUserId: string;
    ProjectVendorId: string | null;
    Status: boolean;
    UserId: string;
    User: User;
}

const initialState: ChatState = {
    projectId: null,
    projectUserList: []
};

const slice = createSlice({
    name: "projects",
    initialState,
    reducers: {
        setProjectId: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                projectId: action.payload
            };
        },
        setProjectUserList: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                projectUserList:
                    action.payload === null
                        ? []
                        : state.projectUserList.concat(action.payload)
            };
        }
    }
});

export const { setProjectId, setProjectUserList } = slice.actions;

export default slice.reducer;
