import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import React from "react";
import { SafeAreaView } from "react-native-safe-area-context";

import HomeContainer from "../Containers/HomeContainer";

import LibraryIcon from "@/Assets/Images/musicicon.svg";

import ArtistProfileContainer from "@/Containers/ArtistProfileContainer";
import { AppTabBar } from "@/Components/AppTabBar";

const Tab = createBottomTabNavigator();
import { ErrorBoundary } from "react-error-boundary";
import { View } from "react-native";
import { Text } from "native-base";
import { Image } from "expo-image";

const HomeIcon = require("@/Assets/Images/homeicon.svg");
const TestView = () => {
    return (
        <View>
            <HomeIcon />
            <Text>Something went ok</Text>
        </View>
    );
};
// @refresh reset
const MainNavigator = () => {
    return (
        <SafeAreaView edges={["bottom"]} style={{ flex: 1 }}>
            <Tab.Navigator
                tabBar={AppTabBar}
                screenOptions={{ headerShown: false }}
            >
                <Tab.Screen
                    name="Home"
                    component={HomeContainer}
                    options={{
                        tabBarIcon: ({ focused }) => (
                            <HomeIcon
                                fontSize={20}
                                color={focused ? "#3DCCC7" : "#F8F9FA"}
                            />
                        ),
                        tabBarLabelPosition: "below-icon",
                        tabBarStyle: [
                            {
                                backgroundColor: "#000",
                                paddingBottom: 20,
                                paddingTop: 15,
                                height: 56
                            }
                        ],
                        tabBarLabelStyle: [
                            {
                                fontSize: 0
                            }
                        ],
                        tabBarLabel: ""
                    }}
                />
                {
                    <Tab.Screen
                        name="Library"
                        component={ArtistProfileContainer}
                        options={{
                            headerTitleStyle: {
                                color: "#000",
                                fontSize: 10,
                                fontWeight: "500"
                            },
                            headerStyle: {
                                height: 48,
                                backgroundColor: "#000"
                            },
                            headerTitleAlign: "left",
                            headerTitle: () => null,
                            tabBarIcon: ({ focused }) => (
                                <LibraryIcon
                                    fontSize={20}
                                    color={focused ? "red" : "r"}
                                />
                            ),
                            tabBarLabelPosition: "below-icon",
                            tabBarStyle: [
                                {
                                    backgroundColor: "#000",
                                    paddingBottom: 20,
                                    paddingTop: 15,
                                    height: 56
                                }
                            ],
                            tabBarLabelStyle: [
                                {
                                    fontSize: 0
                                }
                            ],
                            tabBarLabel: () => null
                        }}
                    />
                }
            </Tab.Navigator>
        </SafeAreaView>
    );
};

export default MainNavigator;
