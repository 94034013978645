import "firebaseConfig";

import { NativeBaseProvider, Center, Text } from "native-base";
import React from "react";
import { StatusBar, View } from "react-native";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";

import { store, persistor } from "./Store";
import "@/Translations";
import { DefaultTheme } from "./Theme/themes";
import ApplicationNavigator from "./Navigators/Application";
import { PlayerProvider } from "./Providers/PlayerProvider";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import posthog from "posthog-js";
posthog.init("phc_HeiNoyucr1QHO9DW6oAiD7Ai8wJyO5FKZQr1BVD4QQL", {
    api_host: "https://app.posthog.com"
});

// Create the error boundary...

const ErrorView = () => (
    <View>
        <Text>Error</Text>
    </View>
);
const App = () => (
    <Provider store={store}>
        {/**
         * PersistGate delays the rendering of the app's UI until the persisted state has been retrieved
         * and saved to redux.
         * The `loading` prop can be `null` or any react instance to show during loading (e.g. a splash screen),
         * for example `loading={<SplashScreen />}`.
         * @see https://github.com/rt2zz/redux-persist/blob/master/docs/PersistGate.md
         */}
        <PersistGate loading={null} persistor={persistor}>
            {/* 
                    // @ts-ignore */}
            <StatusBar backgroundColor="#193C95" />
            <NativeBaseProvider theme={DefaultTheme}>
                <PlayerProvider>
                    <Center
                        bgColor={"#000"}
                        width="100%"
                        height="100%"
                        display={"flex"}
                        alignItems={"center"}
                        style={{
                            margin: 0
                        }}
                    >
                        <ApplicationNavigator />
                    </Center>
                </PlayerProvider>
            </NativeBaseProvider>
        </PersistGate>
    </Provider>
);

export default App;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
