import {
    Box,
    Button,
    Center,
    FormControl,
    Input,
    VStack,
    Text
} from "native-base";
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";

//@ts-ignore
import Logo from "@/Assets/Images/logoserauto-ios-icon.svg";
import styled from "styled-components";

export const Title = styled.span`
    width: 343px;
    height: 128px;
    font-family: Outfit_700Bold;
    font-size: 48px;
    font-weight: 600;
    line-height: 64px;
    text-align: left;
    color: #f8f9fa;
`;

export const Subtitle = styled.span`
    width: 343px;
    height: 36px;
    font-family: Outfit_400Regular;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    color: #f8f9fa;
`;
interface LoginData {
    Email: string;
}

export const SignInContainer = () => {
    console.log("SignInContainer components", Title, Subtitle);
    const [showPassword, setPasswordShow] = useState(false);
    const [message, setMessage] = useState("");
    const [isLoading, setLoading] = useState(false);

    const {
        control,
        formState: { errors },
        handleSubmit
    } = useForm<LoginData>();

    const onSubmit = async (data: LoginData) => {
        setLoading(true);
        setMessage("");

        try {
            const url =
                "https://us-central1-kratekonnect.cloudfunctions.net/sendMagicLink";

            await fetch(url, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    email: data.Email,
                    host:
                        typeof window !== "undefined"
                            ? window.location.origin
                            : null,
                    type: "mobile_web"
                })
            });
            //window.localStorage.setItem('emailForSignIn', email);
            setMessage("Check your email for the magic link!");
        } catch (error: any) {
            setMessage(`Error: ${error.message}`);
        }

        setLoading(false);
    };

    return (
        <Center w="100%">
            <Box safeArea p="2" py="16" w="90%" maxW="290">
                <Title style={{ color: "#fff" }}>FOR YOUR MUSIC</Title>
                <Subtitle>
                    {
                        "Experience music the way it was meant to sound, and support your favorite artists directly."
                    }
                </Subtitle>
                <VStack space={3} mt={10}>
                    <FormControl>
                        <Controller
                            control={control}
                            render={({
                                field: { onChange, onBlur, value, ref }
                            }) => (
                                <Input
                                    width={"full"}
                                    variant={"filled"}
                                    ref={ref}
                                    placeholder="Email"
                                    onBlur={onBlur}
                                    onChangeText={(val) => onChange(val)}
                                    value={value}
                                />
                            )}
                            name="Email"
                            rules={{
                                required: "Field is required",
                                minLength: 3,
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "invalid email address"
                                }
                            }}
                            defaultValue=""
                        />
                    </FormControl>
                    {!!message && <Subtitle>{message}</Subtitle>}
                    {!!errors?.Email && (
                        <Subtitle>{errors?.Email?.message}</Subtitle>
                    )}
                    <Button
                        colorScheme={"white"}
                        width={"full"}
                        _text={{
                            color: "#000"
                        }}
                        _loading={{
                            _text: {
                                color: "#000"
                            }
                        }}
                        isLoading={isLoading}
                        onPress={handleSubmit(onSubmit)}
                    >
                        Continue
                    </Button>
                </VStack>
            </Box>
        </Center>
    );
};
