import React from "react";
import { View, Button, Text, Link } from "native-base";
import { Linking } from "react-native";

const IncompatibleContainer = () => {
    return (
        <View
            style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                maxWidth: 400
            }}
        >
            <Text>
                Sorry, desktops are not compatible with this app, please use a
                mobile device at this time
            </Text>
            <Button
                onPress={() => {
                    Linking.openURL("https://www.serauto.app");
                }}
                colorScheme={"white"}
                width={"full"}
                margin={4}
                _text={{
                    color: "#000"
                }}
                _loading={{
                    _text: {
                        color: "#000"
                    }
                }}
            >
                Go To Website
            </Button>
        </View>
    );
};

export default IncompatibleContainer;
