import { extendTheme } from "native-base";
import { background } from "native-base/lib/typescript/theme/styled-system";

const theme = extendTheme({
    components: {
        Button: {
            baseStyle: {
                _text: {
                    color: "#000"
                }
            },
            defaultProps: {
                colorScheme: "white"
            }
        },
        Input: {
            baseStyle: {},
            defaultProps: {
                variant: "filled"
            }
        }
    },
    colors: {
        // Add new color
        primary: {
            50: "#def1ff",
            100: "#afd1ff",
            200: "#7db2ff",
            300: "#4b94ff",
            400: "#1a75ff",
            500: "#005ce6",
            600: "#0047b4",
            700: "#003382",
            800: "#001f51",
            900: "#000a21"
        },
        tdorange: {
            light: "#FFAB91",
            dark: "#FF5722"
        },
        black: {
            100: "#E0E0E0",
            200: "#9E9E9E",
            300: "rgba(0, 0, 0, 0.6)",
            600: "#1c1b1b",
            900: "#000"
        },
        white: {
            100: "#FFFFFF",
            200: "#FFFFFF",
            300: "#FFFFFF",
            400: "#FFFFFF",
            500: "#FFFFFF",
            600: "#FFFFFF",
            700: "#FFFFFF",
            800: "#FFFFFF",
            900: "#FFFFFF"
        },
        lightBlue: {
            400: "#339ef5",
            500: "#E2F5FC",
            600: "#98a5d4",
            700: "#2B88C3",
            800: "#B6E5F7",
            900: "#1F578C"
        },
        yellow: {
            800: "#f0ff1c"
        },
        background: "#000"
    },
    config: {
        // Changing initialColorMode to 'dark'
        initialColorMode: "dark"
    },
    fontConfig: {
        Outfit: {
            100: {
                normal: "Outfit_100Thin"
            },
            200: {
                normal: "Outfit_200ExtraLight"
            },
            300: {
                normal: "Outfit_300Light"
            },
            400: {
                normal: "Outfit_400Regular"
            },
            500: {
                normal: "Outfit_500Medium"
            },
            600: {
                normal: "Outfit_600SemiBold"
            },
            700: {
                normal: " Outfit_700Bold"
            },
            800: {
                normal: "Outfit_800ExtraBold"
            },
            900: {
                normal: "Outfit_900Black"
            }
        }
    },

    // Make sure values below matches any of the keys in `fontConfig`
    fonts: {
        heading: "Outfit",
        body: "Outfit",
        mono: "Outfit"
    }
});

export default theme;
