import React, { useState, useEffect } from "react";
import { View } from "react-native";

import { firebaseMain } from "@/firebaseConfig";
import MixVerticalComponent from "./MixVerticalComponent";
import { ScrollView, Stack } from "native-base";
import styled from "styled-components";
import { Track } from "@/Models/Track";

const Title = styled.span`
    font-family: Outfit_700Bold;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    text-align: left;
    color: #ffffff;
    margin-bottom: 16px;
`;
const FeaturedTracks = () => {
    const [tracks, setTracks] = useState<Track[]>([]);

    useEffect(() => {
        const fetchTracks = async () => {
            const featureTrackDocs = await firebaseMain
                .firestore()
                .collection("featuredTracks")
                .get();
            const trackRefs = featureTrackDocs.docs.map((doc) => doc.id);
            console.log("featureTrackDocs", featureTrackDocs.docs);

            console.log("trackRefs", trackRefs);
            // Assuming trackRefs.length <= 10
            /*const trackQuerySnapshot = await firebaseMain
                .firestore()
                .collection("tracks")
                .where(
                    firebaseMain.firestore.FieldPath.documentId(),
                    "in",
                    trackRefs
                )
                .get();*/
            /*const tracks = trackQuerySnapshot.docs.map(
                (doc) => ({ id: doc.id, ...doc.data() } as Track)
            );*/

            // Assuming trackRefs.length <= 10
            const trackQuerySnapshot = await firebaseMain
                .firestore()
                .collection("tracks")
                .where(
                    firebaseMain.firestore.FieldPath.documentId(),
                    "in",
                    trackRefs
                )
                .get();

            const trackAndDJPromises = trackQuerySnapshot.docs.map(
                async (doc) => {
                    const track = { id: doc.id, ...doc.data() } as Track;
                    const djDoc = await firebaseMain
                        .firestore()
                        .collection("profiles")
                        .doc(track.djRef)
                        .get();
                    track.dj = { id: djDoc.id, ...djDoc.data() } as DJ;
                    return track;
                }
            );
            const tracks = await Promise.all(trackAndDJPromises);
            setTracks(tracks);
        };

        fetchTracks();
    }, []);

    console.log("tracks", tracks);

    return (
        <View>
            <Title>Mixes For You</Title>
            <ScrollView horizontal={true}>
                <Stack overflowX={"scroll"} space="2.5" direction="row">
                    {tracks.map((track) => (
                        <MixVerticalComponent key={track.id} track={track} />
                    ))}
                </Stack>
            </ScrollView>
        </View>
    );
};

export default FeaturedTracks;
