import { BottomTabBarProps, BottomTabBar } from "@react-navigation/bottom-tabs";
import React from "react";
import { View, Text } from "react-native";
import { FloatingPlayer } from "./FloatingPlayer";
import { Stack } from "native-base";
import { ErrorBoundary } from "react-error-boundary";

export const AppTabBar = (props: BottomTabBarProps) => (
    <ErrorBoundary fallback={<View><Text>Something went wrong</Text></View>}>
        <Stack direction={"column"}>
            <FloatingPlayer />
            <BottomTabBar {...props} />
        </Stack>
    </ErrorBoundary>
);
