import { Track, getTrackPhotoDownloadURL } from "@/Models/Track";
import { Box, IconButton, Menu, Stack } from "native-base";
import React, { useContext, useEffect, useState } from "react";
import { Image } from "expo-image";
import styled from "styled-components";
import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { PlayerContext } from "@/Providers/PlayerProvider";

export const MixedTitle = styled.span`
    font-family: Outfit_700Bold;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    color: #f8f9fa;
`;

export const MixedSubtitle = styled.span`
    font-family: Outfit_400Regular;
    font-size: 10px;
    line-height: 14px;
    text-align: left;
    color: #f8f9fa;
`;

const MixHorizontalComponent = ({
    track,
    queue
}: {
    track: Track;
    queue?: Track[];
}) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [photo, setPhoto] = React.useState<string | null>(null);
    const navigate = useNavigation();

    useEffect(() => {
        if (track.photoName) {
            getTrackPhotoDownloadURL(track).then((url) => {
                setPhoto(url);
            });
        }
    }, [track]);

    const handlePlayClick = () => {
        // Handle play button click
        // ...
        if (queue && queue.length > 0) {
            const queueIndex = queue.findIndex((t) => t.id === track.id);
            navigate.navigate("MusicPlayer", {
                index: Math.max(queueIndex, 0),
                queue
            });
        } else {
            navigate.navigate("MusicPlayer", { index: 0, queue: [track] });
        }
    };

    const handleMenuClick = () => {
        setMenuOpen(true);
    };

    const handleMenuClose = () => {
        setMenuOpen(false);
    };

    const handlePlayOptionClick = () => {
        handleMenuClose();
        // Handle play option click
        // ...
        navigate.navigate("MusicPlayer", { index: 0, queue: [track] });
    };

    const handleEditOptionClick = () => {
        handleMenuClose();
        // Handle edit option click
        // ...
    };

    return (
        <Stack key={track.id} direction={"row"} alignItems="center">
            {photo && (
                <Image
                    source={photo ?? ""}
                    style={{
                        height: 38,
                        width: 38,
                        borderRadius: 12,
                        backgroundColor: "#343a40"
                    }}
                    contentFit="cover"
                    transition={1000}
                />
            )}
            <Stack marginLeft={2} direction={"column"}>
                <MixedTitle>{track.name}</MixedTitle>
                <MixedSubtitle>by {track.dj?.name}</MixedSubtitle>
            </Stack>
            <Box marginLeft="auto" flexDirection={"row"}>
                <IconButton
                    variant="ghost"
                    onPress={handlePlayClick}
                    icon={
                        <Ionicons
                            name="play-circle-outline"
                            size={24}
                            color="#fff"
                        />
                    }
                />

                <Menu
                    isOpen={menuOpen}
                    onClose={handleMenuClose}
                    placement="bottom"
                    trigger={(triggerProps) => {
                        return (
                            <IconButton
                                {...triggerProps}
                                variant="ghost"
                                onPress={handleMenuClick}
                                icon={
                                    <Ionicons
                                        name="ellipsis-vertical"
                                        size={24}
                                        color="#fff"
                                    />
                                }
                            />
                        );
                    }}
                >
                    <Menu.Item onPress={handlePlayOptionClick}>Play</Menu.Item>
                    <Menu.Item onPress={handleEditOptionClick}>Edit</Menu.Item>
                </Menu>
            </Box>
        </Stack>
    );
};

export default MixHorizontalComponent;
