import { FirebaseOptions } from "@firebase/app-types";
import app from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import "firebase/compat/functions";

const config: FirebaseOptions = {
  apiKey: "AIzaSyCRaZGV5f4XC393BbZ7fKEI9Ntqw3LrYq8",
  authDomain: "kratekonnect.firebaseapp.com",
  databaseURL: "kratekonnect.firebaseio.com",
  projectId: "kratekonnect",
  storageBucket: "kratekonnect.appspot.com",
  messagingSenderId: "619131596194",
};
const instance = app.initializeApp(config);

export const firebaseMain = app;

export default instance;
// For more information on how to access Firebase in your project,
// see the Firebase documentation: https://firebase.google.com/docs/web/setup#access-firebase
