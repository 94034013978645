import { PinMessage } from "@/Services/modules/chat/fetchChatPins";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Message, Paginator as TwilioPaginator } from "@twilio/conversations";

import { mapTDMessage, TDConversation, TDPaginator } from "../../Models/index";

export interface ChatState {
    token?: string;
    conversations: TDConversation[];
    pinMode: boolean;
    chatPins: PinMessage[];
    activeConversationId: string;
}

const initialState: ChatState = {
    token: null,
    conversations: [],
    pinMode: false,
    chatPins: [],
    activeConversationId: null
};

const slice = createSlice({
    name: "chat",
    initialState,
    reducers: {
        setChatPins: (state, action: PayloadAction<PinMessage[]>) => {
            return {
                ...state,
                chatPins: action.payload
            };
        },
        setPinMode: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                pinMode: action.payload
            };
        },
        togglePinMode: (state) => {
            return {
                ...state,
                pinMode: !state.pinMode
            };
        },
        setToken: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                token: action.payload
            };
        },
        setConversations: (state, action: PayloadAction<TDConversation[]>) => {
            return {
                ...state,
                conversations: action.payload
            };
        },
        setActiveConversationId: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                activeConversationId: action.payload
            };
        },
        setNewMessageToConversation: (
            state,
            action: PayloadAction<Message>
        ) => {
            console.log(
                `Adding new message. Conversation: ${action.payload.conversation.sid}. Message: ${action.payload.body} `
            );
            const newMessage = mapTDMessage(action.payload);
            const conversationWithMessages: TDConversation[] = [];

            let conversationFound = false;
            for (const c of state.conversations) {
                if (c.externalId === action.payload.conversation.sid) {
                    conversationFound = true;
                    let conversation: TDConversation;
                    conversation = c as TDConversation;
                    if (
                        !conversation.messages.find(
                            (e) => e.sid === newMessage.sid
                        )
                    ) {
                        conversation = {
                            ...conversation,
                            messages: [...conversation.messages, newMessage],
                            lastMessage: newMessage.message,
                            dateUpdated: new Date(
                                newMessage.dateCreated
                            ).valueOf(),
                            unreadCount:
                                action.payload.conversation.sid !==
                                state.activeConversationId
                                    ? Number(conversation.unreadCount) + 1
                                    : Number(conversation.unreadCount)
                        };
                    }
                    conversationWithMessages.push(conversation);
                } else {
                    conversationWithMessages.push(c as TDConversation);
                }
            }

            return {
                ...state,
                conversations: conversationWithMessages
            };
        },
        addNewConversation: (state, action: PayloadAction<TDConversation>) => {
            console.log(
                `Adding new conversation. Conversation Id: ${action.payload.externalId}`
            );
            let newConversationList: TDConversation[];
            if (
                !state.conversations.find(
                    (e) => e.externalId === action.payload.externalId
                )
            ) {
                newConversationList = (
                    state.conversations as TDConversation[]
                ).concat(action.payload);
            } else {
                newConversationList = state.conversations as TDConversation[];
            }
            return {
                ...state,
                conversations: newConversationList
            };
        },
        updateConversationChatName: (
            state,
            action: PayloadAction<{
                externalId: string;
                updatedChatName: string;
            }>
        ) => {
            const updatedConversations: TDConversation[] = [];
            state.conversations.forEach((conversation) => {
                if (conversation.externalId === action.payload.externalId) {
                    conversation = {
                        ...conversation,
                        chatName: action.payload.updatedChatName
                    };
                }
                updatedConversations.push(conversation as TDConversation);
            });
            return {
                ...state,
                conversations: updatedConversations
            };
        },
        markConversationAsRead: (
            state,
            action: PayloadAction<{
                externalId: string;
            }>
        ) => {
            const updatedConversations: TDConversation[] = [];
            state.conversations.forEach((conversation) => {
                if (conversation.externalId === action.payload.externalId) {
                    conversation = {
                        ...conversation,
                        unreadCount: 0
                    };
                }
                updatedConversations.push(conversation as TDConversation);
            });
            return {
                ...state,
                conversations: updatedConversations
            };
        },
        setEarlierMessagesToConversation: (
            state,
            action: PayloadAction<TwilioPaginator<Message>>
        ) => {
            const mappedPrevPageMessageList = action.payload.items.map((m) =>
                mapTDMessage(m)
            );
            const conversationWithMessages: TDConversation[] = [];

            const twilioMessagePaginator: TDPaginator = {
                hasPrevPage: action.payload.hasPrevPage ?? false,
                prevPage: action.payload?.hasPrevPage
                    ? action.payload.prevPage()
                    : null
            };

            state.conversations.forEach((conversation) => {
                if (conversation.externalId === state.activeConversationId) {
                    conversation = {
                        ...conversation,
                        //@ts-ignore
                        messages: mappedPrevPageMessageList.concat(
                            //@ts-ignore
                            conversation.messages
                        ),
                        twilioMessagePaginator
                    };
                }
                conversationWithMessages.push(conversation as TDConversation);
            });
            return {
                ...state,
                conversations: conversationWithMessages
            };
        }
    }
});

export const {
    setToken,
    setConversations,
    togglePinMode,
    setPinMode,
    setChatPins,
    setActiveConversationId,
    setNewMessageToConversation,
    addNewConversation,
    updateConversationChatName,
    markConversationAsRead,
    setEarlierMessagesToConversation
} = slice.actions;

export default slice.reducer;
