import { firebaseMain } from "@/firebaseConfig";
import firebase from "firebase/compat/app";

export type DJ = {
    id: string;
    name: string;
    bio: string;
    photoName?: string;
};

export type Track = {
    id: string;
    name: string;
    status: string;
    genres: string[];
    djRef: string;
    createdAt: firebase.firestore.Timestamp;
    dj?: DJ;
    isPublic: boolean;
    photoName?: string;
    musicName?: string;
};

export const getTrackPhotoRefLocation = (track: Track | DJ) => {
    if (!track.photoName) {
        return null;
    }
    return `tracks/${track.id}/${track.photoName}`;
};

export const getTrackMusicRefLocation = (track: Track) => {
    if (!track.musicName) {
        return null;
    }
    return `tracks/${track.id}/${track.musicName}`;
};

export const getTrackMusicDownloadURL = async (track: Track) => {
    const refLocation = getTrackMusicRefLocation(track);
    if (!refLocation) {
        return null;
    }

    try {
        const fileRef = firebaseMain.storage().ref(refLocation);
        const downloadURL = await fileRef.getDownloadURL();
        return downloadURL;
    } catch (error) {
        console.error("Error getting track music download URL:", error);
        return null;
    }
};

export const getTrackPhotoDownloadURL = async (track: Track | DJ) => {
    const refLocation = getTrackPhotoRefLocation(track);
    if (!refLocation) {
        return null;
    }

    try {
        const fileRef = firebaseMain.storage().ref(refLocation);
        const downloadURL = await fileRef.getDownloadURL();
        return downloadURL;
    } catch (error) {
        console.error("Error getting track photo download URL:", error);
        return null;
    }
};
