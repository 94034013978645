import { Track, getTrackPhotoDownloadURL } from "@/Models/Track";
import { Box } from "native-base";
import React, { useContext, useEffect } from "react";
import { Image } from "expo-image";
import styled from "styled-components";
import { useNavigation } from "@react-navigation/native";
import { TouchableOpacity } from "react-native";
import { PlayerContext } from "@/Providers/PlayerProvider";
import { LocalTrack } from "@/Models/PlayableTrack";

export const MixedTitle = styled.span`
    font-family: Outfit_700Bold;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    color: #f8f9fa;
`;

export const MixedSubtitle = styled.span`
    font-family: Outfit_400Regular;
    font-size: 10px;
    line-height: 14px;
    text-align: left;
    color: #f8f9fa;
`;

const MixVerticalComponent = ({
    track,
    queue
}: {
    track: Track;
    queue?: Track[];
}) => {
    const [photo, setPhoto] = React.useState<string | null>(null);
    const navigate = useNavigation();
    useEffect(() => {
        if (track.photoName) {
            getTrackPhotoDownloadURL(track).then((url) => {
                setPhoto(url);
            });
        }
    }, [track]);

    const handlePlayClick = () => {
        // Handle play button click
        // ...
        if (queue && queue.length > 0) {
            const queueIndex = queue.findIndex((t) => t.id === track.id);
            navigate.navigate("MusicPlayer", {
                index: Math.max(queueIndex, 0),
                queue
            });
        } else {
            navigate.navigate("MusicPlayer", { index: 0, queue: [track] });
        }
    };
    return (
        <TouchableOpacity onPress={handlePlayClick}>
            <Box key={track.id}>
                {photo && (
                    <Image
                        source={photo ?? ""}
                        style={{
                            height: 140,
                            width: 140,
                            borderRadius: 12,
                            backgroundColor: "#343a40"
                        }}
                        contentFit="cover"
                        transition={1000}
                    />
                )}
                <MixedTitle>{track.name}</MixedTitle>
                <MixedSubtitle>by {track.dj?.name}</MixedSubtitle>
            </Box>
        </TouchableOpacity>
    );
};

export default MixVerticalComponent;
