export enum UserRoleEnum {
    COMPANY_ADMIN = "Company_Admin",
    COMPANY_CO_ADMIN = "Company_Co_Admin",
    PROJECT_MANAGER = "Project_Manager",
    PROJECT_ASSISTANT = "Project_Assistant"
}

export enum UserStatus {
    Active = "Active",
    Pending = "Pending",
    Disabled = "Disabled"
}
export interface LoggedInUser {
    firstName: string;
    lastName: string;
    email: string;
    role: UserRoleEnum;
    username: string;
    companyId: string;
    scopes: [];
    userType: string;
    id: string;
}

/**
 * Converts a cognito user attribute objec to LoggedInUser
 * @param userAttributes User Attributes from Cognito
 * @returns a LoggedInUser object
 */
export const mapToLoggedInUser = (userAttributes: any): LoggedInUser => {
    return {
        companyId: userAttributes["custom:companyId"],
        email: userAttributes["email"],
        firstName: userAttributes["given_name"],
        lastName: userAttributes["family_name"],
        role: userAttributes["custom:role"],
        scopes: [],
        userType: userAttributes["custom:userType"],
        username: userAttributes["cognito:username"],
        id: userAttributes["sub"]
    };
};
