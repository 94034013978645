import {
    Dispatch,
    SetStateAction,
    useState,
    useEffect,
    useContext
} from "react";
import {
    AVPlaybackStatus,
    AVPlaybackStatusError,
    AVPlaybackStatusSuccess
} from "expo-av";
import { PlayerContext } from "@/Providers/PlayerProvider";
import { LocalTrack } from "@/Models/PlayableTrack";

export type Controls = {
    positionMillis: number;
    isPlaying: boolean;
    isBuffering: boolean;
    durationMillis: number;
    startTrack: () => void;
    skipToNextTrack: () => void;
    skipToPreviousTrack: () => void;
    seekTo: (millis: number) => void;
};

export type UsePlayerControlsResponse = {
    currentTrack?: LocalTrack;
    controls: Controls;
    currentTrackIndex: number;
    setCurrentTrackIndex: (num: number) => void;
};

export const usePlayerControls = (): UsePlayerControlsResponse => {
    const {
        soundObject,
        currentTrack,
        currentTrackIndex,
        setCurrentTrackIndex,
        durationMillis,
        positionMillis,
        isBuffering,
        isPlaying
    } = useContext(PlayerContext);

    const { queue } = useContext(PlayerContext);

    const startTrack = async () => {
        console.log("startTrack", soundObject.current, isPlaying);
        if (!soundObject.current) {
            return;
        }

        if (isPlaying) {
            await soundObject.current.pauseAsync();
        } else {
            await soundObject.current.playAsync();
        }
    };

    const skipToNextTrack = async () => {
        if (soundObject.current && currentTrackIndex < queue.length - 1) {
            setCurrentTrackIndex(currentTrackIndex + 1);
        }
    };

    const skipToPreviousTrack = async () => {
        if (soundObject.current && currentTrackIndex > 0) {
            setCurrentTrackIndex(currentTrackIndex - 1);
        }
    };

    const seekTo = async (value) => {
        if (soundObject.current) {
            await soundObject.current.setPositionAsync(value);
        }
    };

    return {
        controls: {
            startTrack,
            skipToNextTrack,
            skipToPreviousTrack,
            seekTo,
            durationMillis,
            isPlaying,
            isBuffering,
            positionMillis
        },
        currentTrack,
        currentTrackIndex,
        setCurrentTrackIndex
    };
};
