import { Box, View } from "native-base";
import React from "react";

import FeaturedTracks from "@/Components/FeatureTracks";
import RecentlyPlayed from "@/Components/RecentlyPlayed";

const HomeContainer = () => {
    return (
        <Box height="100%" width="100%">
            <View
                style={{
                    height: "100%",
                    width: "100%"
                }}
            >
                <FeaturedTracks />
                <View style={{ marginVertical: 4 }} />
                <RecentlyPlayed />
            </View>
        </Box>
    );
};

export default HomeContainer;
