import firebase from "firebase/compat";
import React, { useState, useEffect } from "react";
import { View, Text } from "react-native";

import { firebaseMain } from "@/firebaseConfig";
import { Box, FlatList, ScrollView, Stack } from "native-base";
import styled from "styled-components";
import { Track } from "@/Models/Track";
import MixHorizontalComponent from "./MixHorizontalComponent";

const Title = styled.span`
    font-family: Outfit_700Bold;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    text-align: left;
    color: #ffffff;
    margin-bottom: 16px;
`;
const MyUploads = () => {
    const [tracks, setTracks] = useState<Track[]>([]);

    useEffect(() => {
        const fetchTracks = async () => {
            const currentUserId = firebaseMain.auth().currentUser?.uid;
            if (!currentUserId) {
                return;
            }

            const trackQuerySnapshot = await firebaseMain
                .firestore()
                .collection("tracks")
                .where("status", "!=", "processing")
                .where("djRef", "==", currentUserId) // Filter tracks by djRef
                .get();

            const trackAndDJPromises = trackQuerySnapshot.docs.map(
                async (doc) => {
                    const track = { id: doc.id, ...doc.data() } as Track;
                    const djDoc = await firebaseMain
                        .firestore()
                        .collection("profiles")
                        .doc(track.djRef)
                        .get();
                    track.dj = { id: djDoc.id, ...djDoc.data() } as DJ;
                    return track;
                }
            );
            const tracks = await Promise.all(trackAndDJPromises);
            const sortedTracks = tracks.sort(
                (a, b) =>
                    b.createdAt.toDate().getTime() -
                    a.createdAt.toDate().getTime()
            );

            setTracks(sortedTracks);
        };

        fetchTracks();
    }, []);

    console.log("tracks", tracks);

    return (
        <Box flex={1}>
            <Title> My Uploads</Title>
            <FlatList
                data={tracks}
                renderItem={({ item: track }) => (
                    <MixHorizontalComponent
                        key={track.id}
                        track={track}
                        queue={tracks}
                    />
                )}
            />
        </Box>
    );
};

export default MyUploads;
