import { View, Text } from "native-base";
import React from "react";

import MyUploads from "@/Components/MyUploads";
import ProfileSummary from "@/Components/ProfileSummary";
import { ErrorBoundary } from "react-error-boundary";

const ArtistProfileContainer = () => {
    return (
        <ErrorBoundary fallback={<View><Text>Something went wrong</Text></View>}>
        <View
                style={{
                    height: "100%",
                    width: "100%"
                }}
            >
                <ProfileSummary />
                <MyUploads />
            </View>
        </ErrorBoundary>
    );
};

export default ArtistProfileContainer;
