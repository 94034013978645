export default {
    welcome: "Welcome to SerAuto",
    actions: {
        continue: "Continue"
    },
    example: {
        helloUser: "I am a fake user, my name is {{name}}",
        labels: {
            userId: "Enter a user id"
        }
    }
};
