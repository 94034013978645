import { PlayerContextType, LocalTrack } from "@/Models/PlayableTrack";
import {
    Track,
    getTrackMusicDownloadURL,
    getTrackPhotoDownloadURL
} from "@/Models/Track";
import {
    AVPlaybackStatus,
    AVPlaybackStatusError,
    AVPlaybackStatusSuccess,
    Audio
} from "expo-av";
import React, {
    createContext,
    useContext,
    useState,
    ReactNode,
    useRef,
    useEffect
} from "react";

export const PlayerContext = createContext<PlayerContextType | undefined>(
    undefined
);

export const usePlayer = (): PlayerContextType => {
    const context = useContext(PlayerContext);
    if (!context) {
        throw new Error("usePlayer must be used within a PlayerProvider");
    }
    return context;
};

interface PlayerProviderProps {
    children: ReactNode;
}

export const PlayerProvider: React.FC<PlayerProviderProps> = ({ children }) => {
    const [queue, _setQueue] = useState<LocalTrack[]>([]);
    const soundObject = useRef<Audio.Sound>(new Audio.Sound());
    const [currentTrackIndex, _setCurrentTrackIndex] = useState(null);
    const [currentTrack, setCurrentTrack] = useState(null);
    const [positionMillis, setPositionMillis] = useState(0);
    const [durationMillis, setDurationMillis] = useState(0);
    const [isBuffering, setIsBuffering] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlaybackStatusUpdate = (status: AVPlaybackStatus) => {
        if (status.isLoaded) {
            setIsPlaying(status.isPlaying);
            setIsBuffering(status.isBuffering);
            if (status.isPlaying) {
                setPositionMillis(status.positionMillis || 0);
                setDurationMillis(status.durationMillis || 0);
            }
        } else if ((status as AVPlaybackStatusError).error) {
            console.log(
                `Encountered a playback error: ${
                    (status as AVPlaybackStatusError).error
                }`
            );
        }
    };

    useEffect(() => {
        if (soundObject.current) {
            soundObject.current.setOnPlaybackStatusUpdate(
                handlePlaybackStatusUpdate
            );
        }
        return () => {
            soundObject.current.setOnPlaybackStatusUpdate(null);
        };
    }, [soundObject.current]);

    useEffect(() => {
        const loadAudio = async () => {
            await soundObject.current.unloadAsync();
            if (currentTrack) {
                const status = await soundObject.current.loadAsync(
                    { uri: currentTrack.url },
                    { shouldPlay: true }
                );
                if (status.isLoaded) {
                    const successStatus = status as AVPlaybackStatusSuccess;
                    setIsBuffering(!successStatus.isLoaded);

                    setDurationMillis(successStatus.durationMillis || 0);
                    setPositionMillis(successStatus.positionMillis || 0);
                }
            }
        };
        loadAudio();
        return () => {
            //soundObject.current.unloadAsync();
        };
    }, [currentTrack]);

    const setQueue = (tracks: LocalTrack[]) => {
        _setQueue(tracks);
    };

    const setCurrentTrackIndex = (index: number) => {
        _setCurrentTrackIndex(index);
    };

    useEffect(() => {
        setCurrentTrack(queue[currentTrackIndex]);
    }, [currentTrackIndex, queue]);

    return (
        <PlayerContext.Provider
            value={{
                queue,
                setQueue,
                soundObject,
                setCurrentTrackIndex,
                currentTrack,
                currentTrackIndex,
                positionMillis,
                durationMillis,
                isBuffering,
                isPlaying
            }}
        >
            {children}
        </PlayerContext.Provider>
    );
};
