import React, { FC, useContext, useEffect, useState } from "react";
import {
    ActivityIndicator,
    View,
    StyleSheet,
    TouchableOpacity
} from "react-native";

import { Text, Box, Stack } from "native-base";
import { FontAwesome5 } from "@expo/vector-icons";
import { RootStackScreenProps, Routes } from "@/Navigators/utils";
import {
    getTrackMusicDownloadURL,
    getTrackPhotoDownloadURL
} from "@/Models/Track";
import { usePlayerControls } from "@/Hooks/usePlayerControls";
import { useNavigation } from "@react-navigation/native";
import Slider from "@react-native-community/slider";
import { Image } from "expo-image";
import { ErrorBoundary } from "react-error-boundary";
import { PlayerContext, usePlayer } from "@/Providers/PlayerProvider";
import { set } from "react-hook-form";
import { LocalTrack } from "@/Models/PlayableTrack";

function formatTime(milliseconds) {
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const formattedSeconds = String(seconds % 60).padStart(2, "0");
    return `${minutes}:${formattedSeconds}`;
}

const PlayerModal: FC<RootStackScreenProps<Routes.MusicPlayer>> = ({
    route: {
        params: {
            position: defaultPosition = 0,
            index: defaultIndex = 0,
            queue: defaultQueue
        }
    }
}) => {
    const {
        controls: {
            positionMillis,
            durationMillis,
            seekTo,
            startTrack,
            skipToPreviousTrack,
            skipToNextTrack,
            isPlaying,
            isBuffering
        },
        currentTrack,
        currentTrackIndex,
        setCurrentTrackIndex
    } = usePlayerControls();

    const navigate = useNavigation();

    //const [tracks, setTracks] = useState<LocalTrack[]>([]);

    const { setQueue } = usePlayer();

    useEffect(() => {
        const handleQueue = async () => {
            if (defaultIndex >= 0) {
                setCurrentTrackIndex(defaultIndex);
            }
            if (defaultPosition && defaultPosition > 0) {
                seekTo(defaultPosition);
            }

            if (!currentTrack) {
                startTrack();
            }
        };

        handleQueue();
    }, [defaultIndex, defaultPosition]);

    useEffect(() => {
        const loadTrackInfo = async () => {
            const tempTracks = [];
            for (const tempTrack of defaultQueue) {
                const trackUrl = await getTrackMusicDownloadURL(tempTrack);

                const trackPhoto = await getTrackPhotoDownloadURL(tempTrack);

                tempTracks.push({
                    id: tempTrack.id.toString(),
                    url: trackUrl,
                    title: tempTrack.name,
                    artist: tempTrack.dj?.name || "Unknown Artist",
                    artwork: trackPhoto,
                    duration: 26
                });
            }

            //setTracks(tempTracks);
            setQueue(tempTracks);
        };
        loadTrackInfo();
    }, [defaultQueue]);

    const handlePrevious = async () => {
        skipToPreviousTrack();
    };

    const handlePlayPause = async () => {
        startTrack();
    };

    const handleNext = async () => {
        skipToNextTrack();
    };
    const handleCloseModal = async () => {
        navigate.goBack();
    };

    return (
        <ErrorBoundary
            fallback={
                <View>
                    <Text>Something went wrong</Text>
                </View>
            }
        >
            <Stack style={{ height: "100%", justifyContent: "flex-start" }}>
                <TouchableOpacity
                    style={{
                        position: "relative",
                        top: 0,
                        width: "100%",
                        height: 50,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                    onPress={handleCloseModal}
                >
                    <FontAwesome5 name="chevron-down" size={24} color="white" />
                </TouchableOpacity>
                {!currentTrack && <ActivityIndicator style={styles.loader} />}
                {currentTrack && (
                    <View style={styles.container}>
                        {currentTrack?.artwork && (
                            <Image
                                alt=""
                                source={{ uri: currentTrack.artwork || "" }}
                                fallbackElement={
                                    <Box
                                        marginBottom={24}
                                        height={300}
                                        width={300}
                                        backgroundColor={"#343a40"}
                                        borderRadius={16}
                                    ></Box>
                                }
                                resizeMode="cover"
                                style={styles.artwork}
                            />
                        )}

                        <View style={styles.textcontainer}>
                            <Text style={styles.title}>
                                {currentTrack.title}
                            </Text>
                            <Text style={styles.artist}>
                                {currentTrack.artist}
                            </Text>
                            {isBuffering && (
                                <Text style={styles.artist}>Buffering...</Text>
                            )}
                        </View>
                        <Box width="100%" maxH={40}>
                            <Slider
                                style={{ width: "100%", height: 40 }}
                                maximumValue={durationMillis}
                                minimumValue={0}
                                value={positionMillis}
                                thumbTintColor="#005ce6"
                                minimumTrackTintColor="#005ce6"
                                maximumTrackTintColor="#FFFFFF"
                                onSlidingComplete={(value) => {
                                    // Handle track seeking
                                    // ...
                                    seekTo(value);
                                }}
                            />
                        </Box>
                        <View style={styles.positionDurationContainer}>
                            <Text style={styles.positionText}>
                                {formatTime(positionMillis)}
                            </Text>
                            <Text style={styles.durationText}>
                                {formatTime(durationMillis)}
                            </Text>
                        </View>
                        <View style={styles.controlsContainer}>
                            <TouchableOpacity
                                style={styles.controlButton}
                                onPress={handlePrevious}
                            >
                                <FontAwesome5
                                    name="step-backward"
                                    size={24}
                                    color="#FFFFFF"
                                />
                            </TouchableOpacity>
                            <TouchableOpacity
                                style={styles.controlButton}
                                onPress={handlePlayPause}
                            >
                                {isPlaying ? (
                                    <FontAwesome5
                                        name="pause"
                                        size={32}
                                        color="#FFFFFF"
                                    />
                                ) : (
                                    <FontAwesome5
                                        name="play"
                                        size={32}
                                        color="#FFFFFF"
                                    />
                                )}
                            </TouchableOpacity>
                            <TouchableOpacity
                                style={styles.controlButton}
                                onPress={handleNext}
                            >
                                <FontAwesome5
                                    name="step-forward"
                                    size={24}
                                    color="#FFFFFF"
                                />
                            </TouchableOpacity>
                        </View>
                        <Box
                            bg="#000"
                            h={2}
                            w="100%"
                            borderRadius="full"
                            mt={2}
                            mb={4}
                            position="relative"
                        >
                            <Box
                                bg="#3DCCC7"
                                h="100%"
                                borderRadius="full"
                                position="absolute"
                                left={0}
                                width={`${(0 / currentTrack.duration) * 100}%`}
                            />
                        </Box>
                    </View>
                )}
            </Stack>
        </ErrorBoundary>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: 24
    },
    textcontainer: {
        display: "flex",
        width: "100%",
        alignItems: "flex-start",
        justifyContent: "center"
    },
    artwork: {
        width: 300,
        height: 300,
        minWidth: 300,
        minHeight: 300,
        borderRadius: 16,
        marginBottom: 24,
        backgroundColor: "#343a40"
    },
    title: {
        fontSize: 20,
        fontWeight: "bold",
        color: "#FFFFFF",
        marginBottom: 8,
        fontFamily: "Outfit_700Bold"
    },
    artist: {
        fontSize: 16,
        color: "#FFFFFF",
        marginBottom: 16,
        fontFamily: "Outfit_400Regular"
    },
    controlsContainer: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
    },
    controlButton: {
        marginHorizontal: 16
    },
    loader: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center"
    },
    positionDurationContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        marginTop: 8
    },
    positionText: {
        fontSize: 12,
        color: "#FFFFFF"
    },
    durationText: {
        fontSize: 12,
        color: "#FFFFFF"
    }
});

export default PlayerModal;
