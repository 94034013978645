import React, { useState, useEffect } from "react";

import { firebaseMain } from "@/firebaseConfig";
import { Flex, Stack, Avatar } from "native-base";
import styled from "styled-components";
import { DJ, getTrackPhotoDownloadURL } from "@/Models/Track";

const Title = styled.span`
    font-family: Outfit_700Bold;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    text-align: left;
    color: #ffffff;
`;

export const ProfileTitle = styled.span`
    font-family: Outfit_700Bold;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    color: #f8f9fa;
`;

export const ProfileSubtitle = styled.span`
    font-family: Outfit_400Regular;
    font-size: 10px;
    line-height: 14px;
    text-align: left;
    color: #f8f9fa;
    white-space: nowrap;
`;

export const BigBox = styled.div`
    width: 100%;
    height: 86px;
`;

export const ProfileRow = styled.div`
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
`;
const ProfileSummary = () => {
    const [profile, setProfile] = useState<DJ>(null);
    const [photo, setPhoto] = React.useState<string | null>(null);

    useEffect(() => {
        const fetchUserProfile = async () => {
            const userId = firebaseMain.auth().currentUser.uid;
            const profileDoc = await firebaseMain
                .firestore()
                .collection("profiles")
                .doc(userId)
                .get();
            if (profileDoc.exists) {
                setProfile({ ...profileDoc.data(), id: userId } as DJ);
            }
        };

        fetchUserProfile();
    }, []);

    useEffect(() => {
        if (profile?.photoName) {
            getTrackPhotoDownloadURL(profile).then((url) => {
                setPhoto(url);
            });
        }
    }, [profile]);

    return (
        <BigBox>
            <Flex
                justifyContent={"space-between"}
                direction={"row"}
                marginBottom={2}
            >
                <Title> Profile</Title>
            </Flex>
            <ProfileRow>
                <Stack direction={"row"}>
                    {photo && (
                        <Avatar
                            size={"md"}
                            bg="#343a40"
                            marginRight={2}
                            source={{
                                uri: photo ?? ""
                            }}
                        />
                    )}
                    {!photo && (
                        <Avatar size={"md"} bg="#343a40" marginRight={2} />
                    )}
                    <Stack>
                        <ProfileTitle>
                            {profile?.name ?? "DJ DION"}
                        </ProfileTitle>
                        <ProfileSubtitle>{profile?.bio ?? ""}</ProfileSubtitle>
                    </Stack>
                </Stack>
            </ProfileRow>
        </BigBox>
    );
};

export default ProfileSummary;
