import { NavigationContainer, DefaultTheme } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { View } from "native-base";
import React, { useEffect, useRef, useState } from "react";
import { StatusBar } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";

import { AuthListener } from "./AuthListener";
import { navigationRef } from "./utils";

import { StartupContainer } from "@/Containers";
import PlayerModal from "@/Containers/PlayerModal";
import { SignInContainer } from "@/Containers/SignInContainer";
import { firebaseMain } from "@/firebaseConfig";
import TabNavigator from "./TabNavigator";
import LogoHeaderIcon from "@/Assets/Images/logoheader.svg";
import IncompatibleContainer from "@/Containers/IncompatibleContainer";
import posthog from "posthog-js";

const NavTheme = {
    ...DefaultTheme,
    colors: {
        ...DefaultTheme.colors,
        background: "#000"
    }
};

function extractActionCodeFromURL(url: string | URL) {
    console.log("url", url);
    const urlObject = new URL(url);
    const actionCode = urlObject.searchParams.get("code");
    const email = urlObject.searchParams.get("email");

    return { actionCode, email };
}

const Stack = createStackNavigator();
// @refresh reset
const ApplicationNavigator = () => {
    //useInitPlayer();l
    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        const unsubscribe = firebaseMain.auth().onAuthStateChanged((user) => {
            setCurrentUser(user);
            if (user) {
                posthog.identify(
                    user.uid, // Replace 'distinct_id' with your user's unique identifier
                    { email: user.email }
                );
            } else {
                posthog.reset();
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    console.log("currentUser", currentUser);
    const routeNameRef = useRef<string>();

    useEffect(() => {
        const url = window.location.href;

        const { actionCode } = extractActionCodeFromURL(url);

        firebaseMain
            .auth()
            .signInWithCustomToken(actionCode ?? "")
            .then(() => {
                // Successfully signed in
                console.log("signed in");
                //history.replace("/record");
            })
            .catch((error) => {
                // Handle errors
                console.log("signin error", error);
            });
    }, []);

    return (
        <View
            style={{
                flex: 1,
                width: "100%",
                height: "100%",
                backgroundColor: "#000",
                maxWidth: 500
            }}
        >
            <SafeAreaProvider style={{ marginLeft: 8, marginRight: 8 }}>
                <NavigationContainer
                    theme={NavTheme}
                    ref={navigationRef}
                    onReady={() => {
                        routeNameRef.current =
                            navigationRef.getCurrentRoute().name;
                    }}
                    onStateChange={async () => {
                        const currentRouteName =
                            navigationRef.getCurrentRoute().name;

                        // Save the current route name for later comparison
                        routeNameRef.current = currentRouteName;
                    }}
                >
                    <AuthListener />

                    <StatusBar barStyle="light-content" />

                    <Stack.Navigator>
                        <Stack.Screen
                            name="Startup"
                            component={StartupContainer}
                            options={{
                                animationEnabled: true,
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="Incompatible"
                            component={IncompatibleContainer}
                            options={{
                                animationEnabled: true,
                                headerShown: false
                            }}
                        />
                        {currentUser != null ? (
                            <>
                                <Stack.Screen
                                    name="Main"
                                    component={TabNavigator}
                                    options={{
                                        animationEnabled: true,
                                        headerShown: true,
                                        headerStyle: {
                                            backgroundColor: "#000",
                                            maxHeight: 100,
                                            borderColor: "#000",
                                            shadowColor: "#000"
                                        },
                                        headerTitle: "",
                                        headerRight: () => null,
                                        headerLeft: () => {
                                            return <LogoHeaderIcon />;
                                            // return <></>;
                                        }
                                    }}
                                />
                            </>
                        ) : (
                            <>
                                <Stack.Screen
                                    name="Main"
                                    component={SignInContainer}
                                    options={{
                                        animationEnabled: false,
                                        headerShown: false
                                    }}
                                />
                            </>
                        )}
                        <Stack.Screen
                            name="MusicPlayer"
                            component={PlayerModal}
                            options={{
                                presentation: "modal",
                                headerShown: false
                            }}
                        />
                    </Stack.Navigator>
                </NavigationContainer>
            </SafeAreaProvider>
        </View>
    );
};

export default ApplicationNavigator;
