import * as SplashScreen from "expo-splash-screen";
import { Box, Center, Heading } from "native-base";
import React, { useEffect } from "react";

import { navigateAndSimpleReset } from "@/Navigators/utils";

import {
    useFonts,
    Outfit_100Thin,
    Outfit_200ExtraLight,
    Outfit_300Light,
    Outfit_400Regular,
    Outfit_500Medium,
    Outfit_600SemiBold,
    Outfit_700Bold,
    Outfit_800ExtraBold,
    Outfit_900Black
} from "@expo-google-fonts/outfit";
import { useWindowDimensions } from "react-native";
import { useWindowSize } from "@uidotdev/usehooks";

SplashScreen.preventAutoHideAsync();

const StartupContainer = () => {
    const [fontsLoaded] = useFonts({
        Outfit: require("@/Assets/Fonts/Outfit.ttf"),
        Outfit_100Thin,
        Outfit_200ExtraLight,
        Outfit_300Light,
        Outfit_400Regular,
        Outfit_500Medium,
        Outfit_600SemiBold,
        Outfit_700Bold,
        Outfit_800ExtraBold,
        Outfit_900Black
    });

    const scaledSize = useWindowDimensions();
    const { width, height } = useWindowSize();

    console.log("size", width, height, scaledSize);

    const init = async () => {
        console.log("init splash");
        await SplashScreen.hideAsync();
        setTimeout(() => {
            if (width < 800) {
                navigateAndSimpleReset("Main");
            } else {
                navigateAndSimpleReset("Incompatible");
            }
        }, 2000);
    };

    useEffect(() => {
        if (fontsLoaded) {
            init();
        }
    }, [fontsLoaded]);

    return (
        <Box bgColor={"#000"} width="full" height="full" flex={1}>
            <Center height="full">
                <Heading>SerAuto</Heading>
            </Center>
        </Box>
    );
};

export default StartupContainer;
